@import "src/stylehelpers/colors";

.ssyg-container {
  position: relative;
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-family: 'Open Sans';
  &.ant-space {
    width: auto;
    .ant-space-item {
      width: 100%;
    }
  }
}

.ssyg-title {
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 32px !important;
  padding: 0;
}

.ssyg-thanks {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}

.ssyg-feedback {
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  max-width: 480px;
}

.ssyg-link {
  font-weight: 600;
}

.ssyg-image {
  width: 372px;
  height: 222px;
  position: absolute;
  bottom: -105px;
  right: -160px;
}
