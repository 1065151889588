@import "src/stylehelpers/colors";

.single-journal-entry-wrapper {
  cursor: pointer;
  height: auto;
  margin-bottom: 0.5rem;
  padding: 1rem;
  border: 1px solid #D7DCE5;
  border-radius: 8px;
  transition: border 0.3s;

  &.selected {
    transition: border 0s;
    border: 2px solid #555DF2 !important;
    box-shadow: 0px 16px 36px -12px rgba(9, 36, 124, 0.12), 0px 3px 3px -2px rgba(7, 29, 99, 0.1), 0px 12px 16px -8px rgba(9, 36, 124, 0.12);
  }

  &:hover {
    border: 1px solid $neutral-700;
  }
}

.entry-info-box {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.linked-debit-credit-wrapper {
  border: 1px solid #D7DCE5;
  border-radius: 4px;
  padding: 6px 8px;
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
}
.override-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.override-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0.5rem;

  p {
    margin-bottom: 0px;
    margin-left: 0.5rem;
  }
}
