@font-face {
  font-family: "Source Serif Pro";
  src: url("~stylehelpers/fonts/Source_Serif_Pro/sourceserifpro-regular-webfont.woff") format("woff"),
    url("~stylehelpers/fonts/Source_Serif_Pro/sourceserifpro-regular-webfont.woff") format("woff2");
  font-weight: normal;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url("~stylehelpers/fonts/Source_Serif_Pro/sourceserifpro-bold-webfont.woff") format("woff"),
    url("~stylehelpers/fonts/Source_Serif_Pro/sourceserifpro-bold-webfont.woff2") format("woff2");
  font-weight: 700;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url("~stylehelpers/fonts/Source_Serif_Pro/SourceSerifPro-Black.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Open Sans";
  src: url("~stylehelpers/fonts/Open_Sans/opensans-regular-webfont.woff") format("woff"),
    url("~stylehelpers/fonts/Open_Sans/opensans-regular-webfont.woff2") format("woff2");
  font-weight: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("~stylehelpers/fonts/Open_Sans/opensans-semibold-webfont.woff") format("woff"),
    url("~stylehelpers/fonts/Open_Sans/opensans-semibold-webfont.woff2") format("woff2");
  font-weight: 600;
}

@font-face {
  font-family: "Open Sans";
  src: url("~stylehelpers/fonts/Open_Sans/opensans-bold-webfont.woff") format("woff"),
    url("~stylehelpers/fonts/Open_Sans/opensans-bold-webfont.woff2") format("woff2");
  font-weight: 700;
}



.primary {
  font-size: 16px;
  line-height: 24px;
}

.secondary {
  font-size: 14px;
  line-height: 20px;
}

.small {
  font-size: 12px;
  line-height: 16px;
}

.strong {
  font-weight: 600;
}

.greyed {
  color: $grey-6;
}
