.xero-reconnect-modal-wrapper {
  & .ant-modal-header {
    border-bottom: none;
  }
  & .ant-modal-footer {
    border-top: none;
  }
}
.xero-reconnect-modal-body-wrapper {
  align-items: center;
  text-align: center;
}

.xero-reconnect-modal-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  gap: 16px;
}
