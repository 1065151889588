.need-specific-mappings-wrapper {
  margin-top: 1rem;
}

.need-specific-mappings-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2B333A;
}

.select-debit-credit-wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid #D7DCE5;
  background-color:white;
  box-shadow: 0px 16px 36px -12px rgba(9, 36, 124, 0.12), 0px 3px 3px -2px rgba(7, 29, 99, 0.1), 0px 12px 16px -8px rgba(9, 36, 124, 0.12);
  border-radius: 8px !important;
  max-height: calc(100vh - 120px - 7.5rem);
  transition: max-height 0.3s ease-in-out;
  &.update-max-height {
    max-height: calc(100vh - 80px);
  }
}

.select-debit-credit-top-wrapper {
  padding: 24px 24px 0px 24px;
  background-color:  #F6F6FE;
}

.select-debit-credit-middle-wrapper {
  background-color: #F6F6FE;
}

.select-debit-credit-bottom-wrapper{
  display: flex;
  background-color:white;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 0px 24px 24px 24px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow: hidden;
}

.no-catagory-wrapper {
  padding: 1rem 1rem 0 1rem;
}
