.dashboard-header-wrapper {
  top: 20px;
  position: sticky;
  padding: 1rem;
  z-index: 1;

  &.on-top {
    padding: 0 1rem 1rem 1rem ;
  }
}

.search-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem 0 0;
  width: 50%;
  
  .search-bar {
    margin: 1px 0 0 0;
    width: 17.25rem;
  }
  
  .filter-sort-wrapper {
    display: flex;
    margin: 0 1rem 0 0;
    .filter-sort-text{
      font-size: 1rem;
      color: #6A7075;
      font-weight: 600px;
      cursor: pointer;
      margin: 0 1rem 0 1.5rem;
    }
  }
}