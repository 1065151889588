.override-mapping-item {
  border-bottom: 1px solid lightgray !important;
  .ant-collapse {
    flex: 1;
  }
  .ant-collapse-header-text {
    display: block;
    width: 100%;
  }
}

.override-mapping-collapse-panel {
  .ant-collapse-header {
    cursor: pointer !important;

    &:hover .department-mapping-override-default-text {
      opacity: 1;
    }
  }
}

.mapping-override-default-text {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #555DF2;
  opacity: 0;
  transition: opacity 0.3s;
}


.override-mapping-item-header {
  display: flex;
  justify-content: space-between;
  flex: 1;
  width: 100%;
}

.override-mapping-item-name {
  font-size: 16px;
  line-height: 24px;
  color: #2B333A !important;
}

.override-mapping-item-code {
  font-size: 12px;
  line-height: 16px;
  color: #6A7075 !important;
}

.need-specific-mappings-wrapper {
  margin-top: 1rem;
}

.need-specific-mappings-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2B333A;
}

.need-specific-mappings-header {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  gap: 0.5rem;
}

.need-specific-mappings-override-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  border-top: 4px solid #666DF3;
  border-radius: 4px 4px 0px 0px;
}

.override-mapping-wrapper {
  flex: 1 1 auto;
  overflow: scroll;
}
