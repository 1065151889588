.root-app {
  height: 100%;
  width: 100%;
}

.root-main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  height: 72px;
  background-color: white;
  width: 100%;
  z-index: 1;

  &.dashboard-header-on-top {
    box-shadow: 0px 2px 6px -2px rgba(9, 36, 124, 0.16), 0px 16px 56px -24px rgba(9, 36, 124, 0.16), 0px 2px 2px -2px rgba(9, 36, 124, 0.08);
  }
}

.root-main-header-large {
  @extend .root-main-header;
  height: 154px;
  margin-top: -41px
}

.root-rise-logo {
  width: 110px;
  height: 30px;
  padding-left: 1rem;
}
.root-rise-right {
  display: flex;
  align-items: center;
  padding-right: 2rem;
  gap: 2rem;
}

.root-sign-out {
  cursor: pointer;
  min-width: fit-content;
  .ant-typography {
    color: #555df2;
    font-weight: 600;
  }
}

.root-outlet-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  padding-top: 72px;
}
