.need-specific-mappings-header {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  gap: 0.5rem;
}

.need-specific-mappings-tabs-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  border-top: 4px solid #666DF3;
  border-radius: 4px 4px 0px 0px;
  background-color: #FFFFFF;
}

.ant-btn{
  font-size: 14px;
}

.not-selected{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  span {
    color: gray;
    cursor: pointer !important;
  };
}
