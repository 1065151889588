$brand-lavender: #9da7cb;
$brand-deep-blue: #09247c;
$brand-shade-blue: #223a89;
$brand-midnight: #08184d;
$brand-light-midnight: #071d63;

$brand-peach: #fcc4c1;
$brand-coral: #f76b63;
$brand-pomegranate: #f73c31;
$brand-blizzard: #b1e9f8;
$brand-maya: #47d9ff;
$brand-turquoise: #00caff;
$brand-iris: #00ace1;
$brand-sky-blue: #3bc8ed;
$brand-melon: #ffdaa5;
$brand-yellow: #ffa31d;
$brand-pizazz: #ff9700;
$brand-violet: #555DF2;

$black: #141c24;
$beige: #FFF5D6;
$dark: #0F151B;
$dark-yellow: #80661A;
$white: #ffffff;

$grey-1: #f2f5fb;
$grey-2: #eaf2f9;
$grey-3: #dce4ec;
$grey-4: #c8d0d8;
$grey-5: #aab2ba;
$grey-5\.5: #a3a7a7;
$grey-6: #6A7075;
$grey-7: #434b53;
$grey-8: #283038;

$neutral-150: #e6e9ee;
$neutral-400: #aaadb0;
$neutral-700: #2B333A;

$success-tint: #a4e8c3;
$success: #49d186;
$success-shade: #42bc79;

$caution-tint: #ffe699;
$caution: #ffcc33;
$caution-shade: #e6b82e;

$danger-tint: #f08080;
$danger: #e00000;
$danger-shade: #ca0000;

$bad-sentiment: #F9C8C8;
$ok-sentiment: #FFF6DB;
$good-sentiment: #DAF6E6;
