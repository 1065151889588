.modal-body {
    display: flex;
    flex-direction: column;
}

.new-account-box{
    display:flex;
    flex-direction: row;
    img{
        margin-right: 2rem;
    }
    .ant-form-item{
        color:red;
        width:15rem
    }
}

.inactive-account-row{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    .account-name{
        font-weight: 600;
    }
    .account-code{
        font-weight: 400;
        font-size: 12px;
        color: #6A7075
    }
}

.je-and-account-info-box{
    p{
        margin-bottom: 0px;
    }
}

.ant-list-split .ant-list-item {
    border-bottom: none;
}