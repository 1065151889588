@import "src/stylehelpers/colors";

.login-container {
  display: flex;
  margin: auto;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  &.ant-space {
    width: 100%;
    max-width: 24rem;
    .ant-space-item {
      width: 100%;
    }
  }
}

.login-subtitle {
  display: block;
  margin-bottom: 1.5rem;
}

.item-list__custom {
  width: 100%;
  .ant-form-item-label {
    font-size: 0.75rem;
    line-height: 1rem;

    label {
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 600;
      color: #434b53;
    }
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    font-size: 0.75rem;
  }
}

.login-submit-button {
  margin-top: 2rem;
}

.login-new-to-rise {
  margin-top: 0.5rem;
  font-size: 14px;
  line-height: 1rem;
  color: #434b53;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  gap: 24px;
  &-button {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 8px;  
  }
}
.login-new-to-rise-link {
  color: $brand-violet;
  font-weight: 600;
  cursor: pointer;
}
