@import "src/stylehelpers/colors";

.balance-modal-wrapper {
  & .ant-modal {
    width: min-content !important;
    min-width: 520px;
  }
  & .ant-modal-header {
    border-bottom: none;
  }

  & .ant-modal-body {
    display: flex;
    flex-direction: column;
  }
  & .ant-modal-footer {
    border-top: none;
  }
}

.balance-modal-button-wrapper-main-section {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.balance-modal-body-wrapper {
  align-items: center;
  text-align: center;
  max-width: 100%;
}

.balance-modal-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  gap: 16px;
}

.balance-modal-message {
  word-break: break-word;
  font-weight: 400;
}

.balance-modal-link {
  color: $brand-violet;
  text-decoration: underline;
  cursor: pointer;
}
