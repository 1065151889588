@import "src/stylehelpers/colors";

.select-rise-org-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}
.select-org-input {
  &.header-mode {
    .ant-select-selection-item {
      color: $brand-violet !important;
    }
    .ant-select-arrow {
      color: $brand-violet !important;
    }
  }

  &.default-mode {
    width: 100%;
    .ant-select-selector {
      border: 1px solid #cbcfd9 !important;
      border-color: #cbcfd9 !important;
    }
  }
}

.select-rise-org-error-text {
  color: $danger !important;
  font-size: 12px;
}