.ml-4, .mx-4 {
  margin-left: 4px;
}

.ml-6 {
  margin-left: 6px;
}


.ml-16 {
	margin-left: 16px;
}

.mr-16 {
  margin-right: 16px; 
}

.m-auto {
  margin: auto;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-16, .my-16 {
  margin-top: 16px !important;
}

.ml-32 {
	margin-left: 32px;
}

.mr-32 {
	margin-right: 32px;
}

.mr-24, .mx-24 {
	margin-right: 24px;
}

.ml-24, .mx-24 {
  margin-left: 24px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-32, .my-32 {
  margin-top: 32px;
}

.mt-36 {
	margin-top: 36px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-8, .my-8 {
  margin-top: 8px;
}

.mt-4, .my-4 {
  margin-top: 4px;
}

.mt-12, .my-12 {
  margin-top: 12px;
}

.mr-8, .mx-8 {
  margin-right: 8px;
}

.ml-8, .mx-8 {
  margin-left: 8px;
}

.mr-4, .mx-4 {
  margin-right: 4px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-2 {
  margin-bottom: 2 !important;
}

.mb-4, .my-4 {
  margin-bottom: 4px;
}

.pb-0 {
  padding-bottom: 0;
}

.mb-8, .my-8 {
	margin-bottom: 8px !important;
}

.mb-12 {
  margin-bottom: 12px;
}


.mb-16, .my-16 {
	margin-bottom: 16px !important;
}

.mb-24 {
	margin-bottom: 24px !important;
}

.mb-32, .my-32 {
	margin-bottom: 32px;
}
