.integration-card-container {
  width: 100%;
  border: 1px solid #D7DCE5;
  border-radius: 12px;
  padding: 16px;

  input {
    width: 20px;
    height: 20px;
  }
}

.integration-card-logo {
  font-size: 48px;
}