.run-report-modal-form-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.run-report-modal-radio-group {
  width: 100% !important;
  .ant-radio-button-wrapper {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px !important;
    &:first-child {
      border-left-color:#555df2;
    }
  }
}

.run-report-modal-date-picker {
  margin-bottom: 0px;
  &.ant-picker {
    width: 100%;
  }
}

.error-run-report-modal-date-picker {
  margin-bottom: 0px;
  &.ant-picker {
    width: 100%;
    border: 1px solid #E00000 !important;
  }
}

.error-border {
  border: 1px solid #E00000;
}

.invalid-payrun-error-text {
  color: red;
  font-size: 12px;
}