.integration-connect-container {
    margin: 130px auto;
    height: 100%;
    min-width: 800px;
    justify-content: flex-start;
    text-align: left;
    &.ant-space {
      width: 100%;
      max-width: 27rem;
      .ant-space-item {
        width: 100%;
        display: flex;
        justify-content: center;
        .subtitle{
            color:#2B333A;
            padding-left: 24px;
            line-height: 24px;
            width: 100%;
        }
      }
    }
}

.integration-connect-wrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.select-org-wrapper{
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    gap: 1rem;
    p {
        margin-bottom: 0px;
        font-size: 12px;
        color: #40474D;
        font-weight: 600;
    }
}

.integration-connect-divider {
    opacity: 0.6 !important;
}
