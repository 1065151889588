@import "~@risepeopleinc/rcl-react/dist/main.css";
@import "src/stylehelpers/colors";
@import "src/stylehelpers/overrides";
@import "src/stylehelpers/common";
@import "src/stylehelpers/paddings";

.view-content {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 80vw;
  .main-content {
    flex-grow: 1;
    overflow: hidden;
  }
}

.entries-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: scroll;
  max-height: 100%;
  &-header {
    display: flex;
    gap: 8px;
    margin: 1px 0 0 1px;
  }
}

.ant-card {
  padding-left: 0px;
  margin-bottom: 10px;

  .ant-card-body {
    padding-left: 1rem !important;

  }
}

.dashboard-sync-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #6A7075;
  font-weight: 500;
}

.dashboard-sync-spinner {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}

.subtext {
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 400;
  font-family: "Open Sans";
  margin-right: 4px;
}

.entry-debit-credit-text {
  font-size: 14px;
  font-weight: 600;
  font-family: "Open Sans";
  margin-bottom: 0.25rem;
}

.run-report-and-settings-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  .settings-icon{
    height: 22px;
    width: 22px;
    margin-left: 0.5rem;
    cursor: pointer;
  }
  .org-selector{
    width: 50%;
    margin-right: 4rem;
  }
}

.toast-container {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 415px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  //Used only for colored theme
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  // Used when no type is provided
  // toast("**hello**")
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  // Used when no type is provided
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}
.title-wrapper {
  padding: 0 0 0 1rem;
}
