.xero-error-modal-wrapper {
  & .ant-modal-header {
    border-bottom: none;
  }

  & .ant-modal-body {
    display: flex;
    flex-direction: column;
  }
  & .ant-modal-footer {
    border-top: none;
  }
}
.xero-error-modal-body-wrapper {
  align-items: center;
  text-align: center;
  max-width: 100%;
}

.xero-error-modal-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  gap: 16px;
}

.xero-error-modal-error-message {
  word-break: break-word;
  font-weight: 400;
}
