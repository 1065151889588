@import "~@risepeopleinc/rcl-react/dist/main.css";
@import "src/stylehelpers/colors";
@import "src/stylehelpers/fonts";
@import "stylehelpers/overrides";
@import "stylehelpers/common";
@import "stylehelpers/paddings";
@import "stylehelpers/margins.scss";


$sidebar-collapsed: 4rem;
$sidebar-expanded: 20rem;
$app-header-height: 70px !default;
$app-sidebar-width: 320px !default;
$app-breadcrumb-height: 102px !default;
$padding: 1rem;
$sidebar-squish-height: 600px !default;
$app-sidebar-toggle-transition: left 0.5s ease !default;
$black: #000000;

#root {
  width: 100%;
  height: 100%;
}

body {
  width: calc(100%);
  height: calc(100%);
  font-family: "Open Sans",Helvetica Neue,Helvetica,Arial,sans-serif;
  line-height: initial !important;
}

h1 {
  color: $black;
  font-family: "Source Serif Pro", serif;
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 40px;
  padding-bottom: 5px;
}

h2 {
  color: $black;
  font-family: "Source Serif Pro", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

h3 {
  color: #000000;
  font-family: "Open Sans", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}

h4 {
  color: #000000;
  font-family: "Source Serif Pro", serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

h5 {
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}

h6 {
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
}

.rcl-button {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.rcl-alert {
  z-index: 100000;
}

h3 {
  color: $black;
  font-family: "Open Sans", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}

h4 {
  color: $black;
  font-family: "Source Serif Pro", serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

h5 {
  color: $black;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}

h6 {
  color: $black;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
}


.rcl-button {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  flex: none;
  order: 0;
  flex-grow: 0;
}



