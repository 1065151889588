@import "colors";


.table-no-border-bottom {
  border-bottom: none !important;
}

.ant-table-wrapper {
  .ant-table {
    border: none;
  }
}

.ant-dropdown-menu {

  .ant-dropdown-menu-submenu-expand-icon {
    font-size: inherit !important;
  }
}

.ant-drawer-mask {
  background-color: rgba(6,25,87,.45);
}


