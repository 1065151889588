@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.saved-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    animation: fadeOut 1s forwards;
    animation-delay: 1s;

    img {
        margin-right: 1px;
    }

    p {
        margin-bottom: 0px;

    }
}

.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.5s, opacity 0.5s linear;
}

.visible {
    visibility: visible;
    opacity: 1;
}
