.connect-bank-container {
  width: 100%;
  margin: 130px auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  & .connect-bank-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 800px;
    width: 40rem;
    gap: 16px;
  }
  & .connect-with-xero-text {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
  & .tenant-name-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  & .je-settings-tips {
    font-weight: 500;
    color: #2B333A;
    text-align: left;
    display: block;
    padding-left: 24px;
  }
}

.item-list__custom {
  width: 100%;

  .ant-form-item-label {
    font-size: 0.75rem;
    line-height: 1rem;

    label {
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 600;
      color: #434b53;
    }
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    font-size: 0.75rem;
  }
}

.xero-wrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid #D7DCE5;
  border-radius: 8px;
  align-items: center;
  padding: 16px;
  margin-left: 24px;

  .logo {
    font-size: 48px;
    margin-right: 16px;
  }

  .connected-with-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      margin-bottom: 0px;
    }
  }
}

.save-je-settings-button {
  color: white;
  width: fit-content;
  .arrow-right {
    font-size: 16px;
  }

  p {
    margin-bottom: 0px;
  }
}

.list-of-xoc {
  border: 1px solid black;
}
