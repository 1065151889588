.kb-link-wrapper {
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  & .link {
    margin: 0 0.25rem;
    color: #555DF2;
    font-weight: 500;
    text-decoration: underline;
  }
}
