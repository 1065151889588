.further-connection-card-container {
  width: calc(50% - 12px);
  border: 1px solid #D7DCE5;
  border-radius: 12px;
  padding: 16px;

  background: white;

  input {
    width: 20px;
    height: 20px;
  }

  .further-connection-card-title {
    font-size: 16px;
    color: #2B333A;
  }

  .further-connection-card-subtitle {
    color: #6A7075;
    font-size: 14px;
  }
}