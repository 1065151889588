.xero-disconnect-modal-body-wrapper {
    align-items: center;
    text-align: center;
    max-width: 100%;
  }

.xero-disconnect-modal-wrapper {
    & .ant-modal-header {
        border-bottom: none;
    }

    & .ant-modal-body {
        display: flex;
        flex-direction: column;
    }
    & .ant-modal-footer {
        border-top: none;
    }
}

.xero-disconnect-input-confirm-wrapper {
    margin-top: 24px;
    gap: 4px !important;
    & .ant-typography {
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        color: #40474D;
    }
}

.xero-disconnect-modal-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    gap: 16px;
}

.xero-disconnect-title {
    font-size: 24px;
    margin-top: 24px;
}