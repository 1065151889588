
.select-debit-credit-form-wrapper {
  display: flex;
  flex-direction: column;
  .ant-form-item{
    margin-bottom: 2px;
  }
}

.select-debit-credit-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.account-info-wrapper {
  display: flex;
  flex-direction: column;
  .subtitle{
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 600;
    color: #6A7075;
  }
}

.accountsContainer {
  ul {
    max-height: 400px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: inherit;
      background-color: inherit;
    }
  }
}

.remove-button {
  cursor: pointer;
  color: #6A7075;
}

.change-credit-account{
  color: #000;
  &-button {
    cursor: pointer;
    color: #555DF2;
  }
}

.need-specific-mappings-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2B333A;
}
